import React, { useEffect, useState } from 'react';
import css from '@emotion/css';
import mapStyles from '../map-styles';
import styled from '@emotion/styled';
import { BREAKPOINTS } from '../../ui/variables';
import RedMapPin from '../../images/icons/red-map-pin.svg';
import RedMapPinOpaque from '../../images/icons/red-map-pin-opaque.svg';
import { NAV_HEIGHTS } from '../header/types';
import googleLoader from '../../lib/google-loader';

const Map = styled.div`
  height: 100%;
  width: 100%;
`;

interface Location {
  name: string;
  address: string;
  path: string;
  position: {
    lat: number;
    lng: number;
  };
}

const CitiesMap = ({
  id,
  locations,
  extendPoints,
  zoom = 13,
}: {
  id: string;
  locations: Array<Location>;
  zoom?: number;
  extendPoints?: {
    lat: number;
    lng: number;
  }[];
}) => {
  const [displayMarkers, setMarkers] = useState<Array<google.maps.Marker>>([]);
  const [selectedLocationIndex, setSelectedLocation] = useState<number>(0);

  useEffect(() => {
    displayMarkers.forEach(displayMarker => {
      let pin = RedMapPinOpaque;

      if (displayMarker.getTitle() === locations[selectedLocationIndex].name) {
        pin = RedMapPin;
      }

      displayMarker.setIcon({
        url: pin,
      });
    });
  }, [selectedLocationIndex, displayMarkers, locations]);

  useEffect(() => {
    let map: google.maps.Map;

    googleLoader.load().then(google => {
      console.log('zoom: ', zoom);
      map = new google.maps.Map(document.getElementById(id) as HTMLElement, {
        zoom,
        styles: mapStyles,
        disableDefaultUI: true,
      });

      const bounds = new google.maps.LatLngBounds();

      const markers: google.maps.Marker[] = [];
      locations.forEach(location => {
        const marker = new google.maps.Marker({
          position: location.position,
          map,
          title: location.name,
          icon: {
            url: RedMapPin,
          },
        });

        marker.addListener('click', () => {
          const index = locations.findIndex(
            loc => loc.name === marker.getTitle()
          );
          setSelectedLocation(index);
        });

        markers.push(marker);
        bounds.extend(location.position);
      });

      // add arbitrary points to the map that are important to be in view
      if (extendPoints && extendPoints.length > 0) {
        for (const extendPoint of extendPoints) {
          bounds.extend(extendPoint);
        }
      }

      map.fitBounds(bounds);
      setMarkers(markers);
    });
  }, []);

  return (
    <div
      css={css`
        width: 100%;
        height: 720px;
        overflow: hidden;

        @media ${BREAKPOINTS.TABLET} {
          top: ${NAV_HEIGHTS.TABLET};
          height: 600px;
        }
      `}
    >
      <Map id={id} />
    </div>
  );
};

export default CitiesMap;
