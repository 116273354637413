// JSON objects for technical SEO purposes for pages that are not in Contentful.

// Breadcrumb schemas

export const breadcrumbNewYorkCity = {
  '@context': 'https://schema.org/',
  '@type': 'BreadcrumbList',
  itemListElement: [
    {
      '@type': 'ListItem',
      position: 1,
      name: 'Locations',
      item: 'https://www.smalldoorvet.com/locations/',
    },
    {
      '@type': 'ListItem',
      position: 2,
      name: 'New York City Locations',
      item: 'https://www.smalldoorvet.com/locations/new-york-city',
    },
  ],
};

export const breadcrumbBoston = {
  '@context': 'https://schema.org/',
  '@type': 'BreadcrumbList',
  itemListElement: [
    {
      '@type': 'ListItem',
      position: 1,
      name: 'Locations',
      item: 'https://www.smalldoorvet.com/locations/',
    },
    {
      '@type': 'ListItem',
      position: 2,
      name: 'Boston Locations',
      item: 'https://www.smalldoorvet.com/locations/boston',
    },
  ],
};

export const breadcrumbWashington = {
  '@context': 'https://schema.org/',
  '@type': 'BreadcrumbList',
  itemListElement: [
    {
      '@type': 'ListItem',
      position: 1,
      name: 'Locations',
      item: 'https://www.smalldoorvet.com/locations/',
    },
    {
      '@type': 'ListItem',
      position: 2,
      name: 'Washington DC Locations',
      item: 'https://www.smalldoorvet.com/locations/washington-dc',
    },
  ],
};
